<template>
    <div class="reqdetail">
        <!--        需求基本信息-->
        <div class="reqcard">
            <div class="reqinfo">
                <div class="p1">{{ item.postName }}</div>
                <div class="p2">
                    <img src="assets/order_card_location.png"/>
                    <div class="location">{{ item.address }}</div>
                </div>
            </div>

            <div class="reqcard_content">
                <div class="reqcard_content_item">
                    <div class="p4">服务日期</div>
                    <div class="p3">{{ item.time }}</div>
                    <div class="p5">{{ item.categoryName }}</div>
                </div>
                <div class="reqcard_content_item">
                    <img v-for="(pic,index) in item.pictures" :key="index" :src="pic" />
                </div>
                <div class="reqcard_content_item reqcard_content_item_extend">
                    <div class="p6">投标说明&要求</div>
                    <div class="p7">
                        <div v-html="item.info"></div>
                    </div>
                </div>
            </div>
        </div>

<!--        &lt;!&ndash;        投标人数&ndash;&gt;-->
<!--        <div class="reqcard">-->
<!--            <div class="people_title">已投标人数</div>-->
<!--            <div class="people_imgs" v-if="item.campaigns!=null && item.campaigns.length >0 ">-->
<!--                <img v-for="(img,index) in item.campaigns" :key="index" :src="img.avatar" :class="index==0?'':'imgsHeader'"/>-->
<!--            </div>-->
<!--            <div v-else class="nopeople">-->
<!--                暂无人员投标-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="order_card_detail order_card_detail_dingbiaoinfo" v-if="item.campaignVo!=null && item.campaignVo!=undefined">-->
<!--            <div class="labelinfo">定标人信息</div>-->
<!--            <div class="order_card_headerImg">-->
<!--                <img :src="item.campaignVo.avatar"/>-->
<!--                <div class="peoplesinfo">-->
<!--                    <div class="p0 p1">{{ item.campaignVo.name }}</div>-->
<!--                    <div class="p0 p2">{{ item.campaignVo.idCard }}</div>-->
<!--                    <div class="p3">{{ item.campaignVo.profession }}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "ReqDetail",
        // data(){
        //     return{
        //         info:'1.要求本科以上学历 <br> 2.<br> 3.<br> 4.<br> 5.<br> 6.<br>'
        //     }
        // },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                console.log('item',this.item)
                document.title="需求详情";
            },
            // toRequestList(){
            //     this.$router.push({path:'/ReqList'})
            // }
        }
    }
</script>

<style scoped>
    .reqdetail{
        width: 750px;
        background-color: #E7EAF1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }


    .reqdetail .reqcard{
        border-radius: 10px;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
    }
    .reqdetail .reqcard .reqinfo{
        margin-left: 30px;
        margin-top: 16px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
    }
    .reqdetail .reqcard .reqinfo .p1{
        font-size: 26px;
        font-weight: bold;
    }
    .reqdetail .reqcard .reqinfo .p2{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 22px;
        color: #A79F9F;
        margin-top: 10px;
        margin-bottom: 16px;
    }
    .p2 .location{
        margin-left: 10px;
    }
    .reqdetail .reqcard .reqinfo .p2 img{
        width: 24px;
        height: 24px;
    }
    .reqcard .reqcard_content{
        border-top: 2px #E4E4E4 dotted;
    }

    .reqcard .reqcard_content .reqcard_content_item{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 26px;
        margin-top: 16px;
        margin-left: 30px;
    }

    .reqcard_content_item .p4{
        color: #A79F9F;
    }

    .reqcard_content_item .p3{
        flex: 1;
        margin-left: 16px;
    }

    .reqcard_content_item .p5{
        margin-right: 30px;
    }

    .reqcard_content_item img{
        margin-right: 20px;
        width: 150px;
        height: 150px;
    }

    .reqcard_content_item .p6{
        color: #A79F9F;
    }

    .reqcard_content_item .p7{
        margin-top: 16px;
        margin-right: 30px;
    }

    .reqcard_content_item_extend{
        padding-bottom: 16px;
        flex-direction: column !important;
        align-items: unset !important;
    }

    .reqdetail .people_title{
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
    }

    .reqdetail .people_imgs{
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
        height: 80px;
        margin-bottom: 30px;
    }
    .reqdetail .people_imgs img{
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border:0px;
    }

    .imgsHeader{
        margin-left: -40px;
    }

    .reqcard_money{
        letter-spacing: 2px;
        margin-top: 30px;
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        align-items: center;
        margin-bottom: 30px;
    }

    .reqcard_money .lable{
        color: #A79F9F;
        width: 120px;
        font-size: 26px;
    }

    .reqcard_money .money1{
        flex:1;
        margin-left: 20px;
        margin-right: 22px;
    }

    .reqcard_money input[type=text]{
        display: block;
        padding:0;
        /*width: 260px;*/
        height: 88px;
        border:2px #DBDBDB solid;
        border-radius: 10px;
        flex:1;
        width: 100%;

        margin-left: 20px;
        margin-right: 22px;
        font-size: 38px;
        padding-left: 10px;
        letter-spacing: 2px;
    }

    .reqcard_money .btn{
        width: 200px;
        height: 80px;
        background-color: #226DF8;
        box-shadow: 0px 2px 4px rgba(117,164,245,0.5);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;

        letter-spacing: 2px;
    }

    .reqdetail .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqdetail .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }

    .order_card_detail{
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        font-size: 26px;
        margin-right: 30px;
    }

    .order_card_detail .order_card_headerImg{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
    }
    .order_card_detail .order_card_headerImg img{
        width: 60px;
        height: 60px;
        border-radius: 60px;
        margin-right: 0 !important;
    }

    .order_card_detail .order_card_headerImg .peoplesinfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
    }
    .order_card_detail .order_card_headerImg .peoplesinfo .p0{
        margin-bottom: 16px;
    }

    .order_card_detail_dingbiaoinfo{
        display: flex !important;
        flex-direction: column !important;
        width:160px !important;
        color: #A79F9F;
        font-size: 26px;
    }



    .reqdetail .people_title{
        font-size: 28px;
        margin-left: 30px;
        margin-top: 20px;
    }

    .reqdetail .people_imgs{
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
        height: 80px;
        margin-bottom: 30px;
    }
    .reqdetail .people_imgs img{
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border:0px;
    }

</style>
